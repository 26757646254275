
  const userEditorScriptEntry = { editorReady() {} }
  const editorScriptEntry = userEditorScriptEntry;

  

  import { initI18nWithoutICU as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  

    var createExperiments = null;
    var createWidgetExperiments = null;
    

  
import { createHttpClient } from '@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp';
    


  import { createOwnerBILogger as biLogger } from '/home/builduser/work/988aeed2e9be6ea4/packages/rise-app-site-plugin/target/generated/bi/createBILogger.ts';

  
  import { editorReadyWrapper, onEventWrapper, createFlowAPIFabric, exportsWrapper, getAppManifestWrapper } from '@wix/yoshi-flow-editor/runtime/esm/editorScript.js';

  let editorReady = editorScriptEntry.editorReady;
  const onEvent = onEventWrapper({ onEvent: editorScriptEntry.onEvent });
  const sentryConfig = {
    DSN: 'https://5463bad232df48319111715b8ebe67cf@sentry.wixpress.com/5895',
    id: 'undefined',
    projectName: 'rise-app-site-plugin',
    teamName: 'ecom-rise-app',
    errorMonitor: true,
  };
  const experimentsConfig = {"scopes":["test"],"centralized":true};
  const translationsConfig = {"icuEnabled":false,"defaultTranslationsPath":"/home/builduser/work/988aeed2e9be6ea4/packages/rise-app-site-plugin/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};
  const defaultTranslations = {"app.widget.welcome":"Hello","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to","checkoutSlot.redeemStoreCredit.codeAlreadyInUse.errorMessage":"This code is already in use.","checkoutSlot.redeemStoreCredit.cta":"Apply","checkoutSlot.redeemStoreCredit.upToOrderTotal.warningMessage":"You can only use up to the total amount of your order.","checkoutSlot.redeemStoreCredit.creditEmpty.errorMessage":"Your store credit is empty.","checkoutSlot.redeemStoreCredit.title":"You have {amount} in store credit","checkoutSlot.redeemStoreCredit.title.new":"You have {{amount}} in store credit","checkoutSlot.paymentDetails.enterStoreCredit.error.general":"We’re experiencing a technical issue on our end. Refresh the page and try again.","checkoutSlot.paymentDetails.enterStoreCredit.error.serviceDown":"Sorry, we can't accept store credit right now because of a technical issue. Please continue without store credit or try again later.","checkoutSlot.paymentDetails.enterStoreCredit.error.disabled":"Your store credit has been disabled. Please contact us for more details. ","checkoutSlot.paymentDetails.enterStoreCredit.error.noBalance":"You don't have any remaining store credit.","checkoutSlot.paymentDetails.enterStoreCredit.error.wrongCurrency":"The store credit currency doesn't match the currency of the site.","checkoutSlot.paymentDetails.enterStoreCredit.error.expired":"The store credit has expired."};
  const createFlowAPI = createFlowAPIFabric({
    sentryConfig,
    experimentsConfig,
    translationsConfig,
    defaultTranslations,
    biLogger,
    shouldUseEssentials: true,
    artifactId: 'rise-app-site-plugin',
    appDefId: '56c4b3f5-a5f2-46bd-afb7-414136363b23',
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: 'assets/locales',
  });

  const useAPIsFromExports = typeof editorScriptEntry.exports === "function";
  if (editorReady) {
    editorReady = editorReadyWrapper({
      editorReady,
      createFlowAPI,
      shouldSkipAPIOverrides: useAPIsFromExports,
    });
    if (false) {
      const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
      editorReady = hot(module, editorReady)
    }
  }
  export { editorReady };
  export { onEvent };
  export const exports = useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports;
  export const getAppManifest = editorScriptEntry.getAppManifest ? getAppManifestWrapper(editorScriptEntry.getAppManifest, createFlowAPI) : undefined;
  export const handleAction = editorScriptEntry.handleAction;
  
